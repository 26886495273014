.pano-hint-box {
    background-color: rgb(0, 0, 0);
    width: 180px;
    position: absolute;
    padding: 0.5em 1em;
    margin: auto;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.8);
    z-index: 50;
    border-radius: 0 2em 2em 0;
    top: 40vh;
    float: left;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
    text-align: center;
}

.pano-hint-box.hide {
    opacity: 0;
}