
.overlay-modal-hide {
    display: none;
}

.overlay-modal-show {
    display: flex;
}

/* This is on the wrapper for the whole modal */
.overlay-modal {
  width: 92vw;
  height: 92vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  z-index: 998;
  padding: 4vh 4vw;
}

.modal-outer-container {
  position: relative;
  margin: auto;
}

.modal-body-container {
  background: #1a1a1a;
  max-width: 40vw;
  border-radius: 2em;
  z-index: 999;
  box-shadow: 0 0 20px -4px #000000;
}

.modal-close-button {
  font-size: 1em;
  padding: 1.35em 0;
  color: #666;
  transition: all ease-in 0.2s;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  align-items: flex-start;
  border-radius: 0 0 2em 2em;
  background: transparent;
}

.modal-close-button:hover {
  cursor: pointer;
  color: #4bdb4b;
  transition: all ease-in 0.2s;
  background: rgba(100, 100, 100, 0.25);
}

.overlay-modal.overlay-modal-show {
  animation: modal-open 0.2s;
}

@keyframes modal-open {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

.modal-media {
  align-items: center;
  justify-content: center;
}

.modal-header h1 {
  font-size: 1.8em;
  padding: 0.8em 0.2em 0 0.2em;
}

.modal-header h2 {
  font-weight: 300;
  font-size: 1em;
  padding: 0;
  text-transform: uppercase;
}

.modal-caption {
  display: flex;
  flex-direction: column;
  flex: 1 3 500px;
  align-items: flex-start;
  justify-content: space-between;
}

.modal-body.welcome {
  line-height: 1.5em;
  margin-bottom: 1em;
  margin-top: 1em;
  text-align: center;
  font-size: 1.2em;
}

.modal-body.welcome div {
  margin: 0.5em;
}

.modal-quote {
  margin: 5em 2em 2em 2em;
  align-items: flex-end;
}

@media only screen and (max-width: 599px) {
  .modal-body-container {
    margin: auto 3em;
    max-width: 85vw;
  }

  .modal-body-container {
    flex-direction: column;
    margin: auto;
  }

  .modal-left {
    flex: 1;
  }

  .modal-left img {
    max-height: 300px;
  }

  .show-mobile {
    display: block;
  }

  .show-desktop {
    display: none;
  }

  .modal-right {
    display: flex;
    flex: 2 1 50%;
    justify-content: left;
  }

  .modal-header h1 {
    font-size: 1.3em;
  }

  .modal-body {
    margin: 0.6em 1em 1.5em 1em;
    font-size: 0.9em;
    line-height: 1.3em;
  }

  .modal-quote {
    margin: 1em;
    font-size: 0.85em;
  }

  blockquote:before {
    margin-top: -2em;
  }

  .modal-close-button {
    background: rgba(0, 0, 0, 0.8);
  }

  .modal-close-button:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  blockquote {
    font-size: 0.85em;
  }
}

@media only screen and (min-width: 600px) {
  .modal-body-container {
    flex-direction: row;
    margin: auto;
    max-width: 70vw;
  }

  .modal-body {
    padding: 0 2ch;
    font-size: 1em;
    line-height: 1.2em;
  }

  .show-mobile {
    display: none;
  }

  .show-desktop {
    display: block;
  }
}

@media only screen and (min-width: 900px) {
  .modal-body-container {
    margin: auto;
    max-width: 500px;
  }

  .modal-body {
    padding: 0 2ch;
    line-height: 1.4em;
  }

  h2 {
    font-size: 2em;
  }
}
