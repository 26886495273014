.pano-street-sign-container {
    position: absolute;
    z-index: 80;
    width: 100vw;
    margin-top: 1.5em;
}

.pano-street-sign {
    display: inline-block;
    font-size: 1.2em;
    font-weight: 700;
    padding: 0 1.2em;
    line-height: 3em;
    height: 3em;
    border-radius: 3em;
    background-color: #000000;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
}


@media only screen and (max-width: 799px) {
    .pano-street-sign-container {
        font-size: 1em;
        top: 5em;
    }

    .pano-street-sign {
        width: 80vw;
    }
}