.menu-icon {
    cursor: pointer;
    font-size: 1.2em;
    padding: 0 1.2em;
    line-height: 3em;
    height: 3em;
    border-radius: 2em;
    background-color: #000000;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.4);
    margin-bottom: 0.5em;
}

a.menu-icon-social {
    display: block;
    width: 1em;
    color: #ffff;
}

.menu {
    height: 100%;
    width: 300px;
    position: fixed;
    border-right: 1px solid #2f2f2f;/* Stay in place */
    z-index: 1000;
    top: 0;
    left: -300px;
    background-color: #000;
    overflow-x: hidden;
    padding-top: 1em;
    transition: 0.5s;
}

.menu.slideout {
    left: 0 !important;
}

.menu ul.burger-menu-list {
    list-style: none;
    padding: 1.2em;
    margin: 0;
}

.menu li.burger-menu-list-item {
    margin: 1.2em 0;
    width: 100%;
    color: #eee;
    border: 1px solid #eee;
    padding: 0.8em 0;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.4s;
}

.menu li.burger-menu-list-item:hover {
    color: #333;
    background-color: #eee;
}

.menu .menu-close-container {
    color: #888;
    text-transform: uppercase;
    font-size: 0.9em;
    text-align: center;
    margin-top: 1em;
}

.menu-close {
    cursor: pointer;
}

@media only screen and (max-width: 799px) {
    .menu-icon {
        width: 80vw;
        margin: 0 auto;
    }

    .menu {
        width: 90vw;
        left: -90vw;
    }
}