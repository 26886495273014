.timeline-overlay-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  padding: 0;
}

.timeline-overlay-modal.overlay-modal-show {
  animation: modal-open 0.2s;
}

.timeline-modal-outer-container {
  position: relative;
  margin: auto;
}

.timeline-modal-body-container {
  background: #fafafa;
  color: #000;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
}

.timeline-modal-close-button {
  font-size: 0.9em;
  padding: 0.8em 0;
  color: #222;
  transition: all ease-in 0.2s;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  align-items: flex-start;
  background: transparent;
}

.timeline-modal-close-button:hover {
  cursor: pointer;
  color: #ffffff;
  transition: all ease-in 0.2s;
  background: #222;
}

@keyframes modal-open {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

.timeline-modal-body {
  line-height: 1.3em;
  margin-bottom: 5em;
  margin-top: 1em;
  text-align: justify;
  font-size: 1.1em;
}

.timeline-modal-body h1 {
  text-align: center;
  font-size: 1.3em;
  font-weight: 400;
  text-transform: uppercase;
}

.timeline-modal-body h2 {
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
  padding: 1em 0;
  border-bottom: 1px solid #ddd;
}

.timeline-modal-body img {
  width: 80%;
  max-width: 768px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.timeline-modal-body iframe {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.timeline-modal-body p.centered {
  text-align: center;
}

.timeline-modal-quote {
  margin: 5em 2em 2em 2em;
  align-items: flex-end;
}

ul.timeline-toc-list {
  margin: auto;
  padding: 0;
}

ul.timeline-toc-list li {
  max-width: 500px;
  list-style: none;
  text-align: center;
  margin: 0.7em auto;
}

ul.timeline-toc-list a,ul.timeline-toc-list a:active,ul.timeline-toc-list a:visited {
  display: block;
  max-width: 500px;
  list-style: none;
  font-size: 1em;
  padding: 0.8em 1em;
  text-align: center;
  margin: 0.7em auto;
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 0.5em;
  color: #000;
  transition: 0.2s ease-in-out all;
}

ul.timeline-toc-list li a:hover {
  background-color: #222;
  border: 1px solid #555;
  border-radius: 0.5em;
  color: #fff;
}

blockquote {
  margin: 0;
  font-style: italic;
  color: #666;
  padding: 0.3em 1.2em;
  border-left: 3px solid #aaa;
  font-size: 1.1em;
}

a.timeline-back-to-top {
  display: block;
  margin: 3em;
  text-align: center;
  color: #777;
  font-size: 0.9em;
}

@media only screen and (max-width: 599px) {
  .timeline-modal-body-container {
    margin: auto 3em;
    max-width: 100vw;
  }

  .timeline-modal-body-container {
    flex-direction: column;
    margin: auto;
  }

  .show-mobile {
    display: block;
  }

  .show-desktop {
    display: none;
  }

  .timeline-modal-right {
    display: flex;
    flex: 2 1 50%;
    justify-content: left;
  }

  .timeline-modal-header h1 {
    font-size: 1.3em;
  }

  .timeline-modal-body {
    margin: 0.6em 1em 1.5em 1em;
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .timeline-modal-quote {
    margin: 1em;
    font-size: 0.85em;
  }

  blockquote:before {
    margin-top: -2em;
  }

  .timeline-modal-close-button {
    background: rgba(0, 0, 0, 0.8);
  }

  .timeline-modal-close-button:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  blockquote {
    font-size: 0.85em;
  }
}

@media only screen and (min-width: 600px) {
  .timeline-modal-body {
    margin: 1em auto 5em auto;
    max-width: 70vw;
    padding: 0 2ch;
    line-height: 1.2em;
  }

  .show-mobile {
    display: none;
  }

  .show-desktop {
    display: block;
  }
}

@media only screen and (min-width: 900px) {
  .timeline-modal-body {
    margin: 1em auto 8em auto;
    max-width: 960px;
  }

  .timeline-modal-body {
    padding: 0 5ch;
    line-height: 1.4em;
  }

  h2 {
    font-size: 2em;
  }
}
