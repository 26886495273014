.pano-scroller {
    height: 90vh;
    overflow-x: scroll;
    overflow-y: hidden;
    cursor: grab;
    user-select: none;
}

.pano-scroller-hidden {
    display: none;
}

.pano-images {
    white-space: nowrap;
    text-align: left;
}

.pano-images img {
    height: 90vh;
    z-index: 1;
    user-select: none;
    pointer-events: none;
}

.pano-scroller h1 {
    color: #fff;
    font-size: 1em;
}

@media only screen and (max-width: 799px) {
    .pano-scroller {
        height: 90vh;
    }

    .pano-images img {
        height: 90vh;
    }
}
