.layout-page-container {
    background-color: #eee;
    color: #555555;
    padding-top: 1em;
    padding-bottom: 1em;
    margin: 0 auto 10em auto;
}

.layout-page {
    font-size: 1.2em;
    line-height: 1.5em;
    max-width: 760px;
    margin: 0 auto;
    padding: 1em 0;
}

.layout-page h1 {
    line-height: 1.4em;
    text-transform: uppercase;
    color: #000000;
}

.layout-page img {
    width: 100%;
    max-width: 740px;
    padding: 0 10px;
}

.layout-page p {
    margin-top: 0.5em;
    text-align: justify;
}

.layout-page p a, .layout-page p a:visited, .layout-page p a:active {
    color: #000000;
    font-weight: 700;
}

.layout-page p a:hover {
    text-decoration: underline;
}

.layout-page .button-link {
    border: 1px solid #cccccc;
    padding: 1em;
    color: #000;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 700;
    background: #ffffff;
    cursor: pointer;
    transition: border 0.2s ease-in-out;
}

.layout-page .button-link:hover {
    border: 1px solid #555555;
}

hr {
    border: none;
    border-top: 1px solid #c8c8c8;
    margin-top: 1.8em;
    margin-bottom: 1.8em;
}

@media only screen and (max-width: 999px) {
    .layout-page {
        font-size: 1em;
        line-height: 1.3em;
        padding: 1em;
        margin-top: 5em;
    }
}
