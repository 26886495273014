.pano-dot-container {
    position: relative;
    float: left;
}

.pano-dot {
    width: 2.5em;
    height: 2.5em;
    top: -1.25em;
    left: -1.25em;
    position: absolute;
    background-color: #009899;
    border-radius: 2.5em;
    border: 0.5em solid #0A5758;
}

.pano-dot:hover {
    cursor: pointer;
}

@media only screen and (max-device-width: 399px) {
    .pano-dot {
        width: 1.6em;
        height: 1.6em;
        top: -0.8em;
        left: -0.8em;
        border-radius: 1.6em;
        border-width: 0.3em;
    }
}
