header {
    padding: 1.5em;
    position: absolute;
    z-index: 100;
    display: inline-block;
    left: 0;
    top: 0;
}

.middle {
    width: 250px;
    text-align: center;
    flex: auto;
}

.middle h2 {
    font-size: 1.8em;
}

@media only screen and (max-width: 799px) {
    header {
        padding: 1.5em 0;
        width: 100vw;
    }
}
