footer {
    font-size: 0.9em;
    line-height: 1.3em;
    color: #888888;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.4em 0.8em;
    top: 90vh;
    background-color: #000000;
    display: flex;
    flex-direction: column;
}

footer a, footer a:active, footer a:visited {
    color: #888888;
    transition: 0.4s ease-in;
}

footer a:hover {
    color: #ffffff;
    text-decoration: none;
    transition: 0.4s ease-in;
}

footer > div {
    margin: 0.3em;
    flex-direction: column;
}

footer > div div {
    flex-direction: row;
}

footer div.attribution-box {
    flex-shrink: 1;
    font-size: 0.85em;
}

footer div.footer-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

footer div.social-box {
    padding-bottom: 8px;
}

footer div.social-box a {
    margin-left: 0.2em;
    padding: 0.3em;
    font-size: 1.8em;
}

footer div.logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

footer div.logo-box img {
    height: 36px;
    max-height: 36px;
    filter: grayscale(100%);
    transition: all 0.2s linear;
}

footer div.logo-box img:hover {
    filter: grayscale(0%);
}

footer div.logo-box > div.logo {
    margin: 0 1em;
}

@media only screen and (max-width: 799px) {
    footer {
        top: 88vh;
        padding: 0.5em 0.8em;
        font-size: 0.8em;
    }

    footer div.footer-top {
        flex-direction: column;
    }

    footer div.logo-box {
        flex-direction: row;
        width: 100vw;
        overflow-x: scroll;
    }

    footer div.logo {
        display: flex;
    }

    footer div.logo-box img {
        max-height: 30px;
    }
}
